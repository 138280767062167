<template>
    <div class="selector" :class="[$mq]">
        <div class="option" @click="switchFilter('tabOne')" :class="{ selected: selectedDefault == 'tabOne' }">
            <div class="text" :class="{ selected: selectedDefault == 'tabOne' }" v-html="tabOneText"></div>
        </div>
        <div class="option" @click="switchFilter('tabTwo')" :class="{ selected: selectedDefault == 'tabTwo' }">
            <div class="text" :class="{ selected: selectedDefault == 'tabTwo' }" v-html="tabTwoText"></div>
        </div>
        <div class="option" @click="switchFilter('tabThree')" :class="{ selected: selectedDefault == 'tabThree' }" v-if='tabThreeText != ""'>
            <div class="text" :class="{ selected: selectedDefault == 'tabThree' }" v-html="tabThreeText"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Tabs',
    props: {
        tabOneText: {
            type: String,
            default: 'textHere'
        },
        tabTwoText: {
            type: String,
            default: 'textHere'
        },
        tabThreeText: {
            type: String,
            default: ''
        },
        selected: {
            type: String,
            default: 'tabOne'
        }
    },
    data() {
        return {
            selectedDefault: 'tabOne'
        }
    },
    methods: {
        switchFilter(tab) {
            this.selectedDefault = tab
            this.$emit('switchClick', tab)
        }
    },
    mounted() {
        this.selectedDefault = this.selected
    }
}
</script>

<style lang="scss" scoped>
.selector {
    @include display-flex();
    @include justify-content(space-between);
    @include align-items(center);
    @include border-radius(4px);
    width: calc(100%);
    height: 45px;
    padding: 3px;
    background-color: #fff;
    .option {
        @include display-flex();
        @include justify-content(center);
        @include align-items(center);
        @include border-radius(4px);
        width: 49%;
        text-align: center;
        height: 34px;
        cursor: pointer;

        &.selected {
            font-family: $text-sbold;
            background-color: $main-t80;
            color: $main-dark;
            cursor: default;
        }
        &:hover:not(.selected) {
            @include bgHover(#fff);
        }
        .text {
            @include font-size(m);
            @include text-ellipsis();
            width: 100%;
            font-family: $text;
            color: $neutro-s50;
            text-align: center;

            &.selected {
                font-family: $text-sbold;
                background-color: $main-t80;
                color: $main-dark;
                cursor: default;
            }
        }
    }

    &.portrait {
        .option {
            .text {
                @include font-size(xs);
            }
        }
    }
}
</style>
